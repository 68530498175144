<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Two-Factor Authentication 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter the two-factor code that you received in your email address.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="validateForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="confirmTwoFactor"
          >
            <!-- email -->
            <b-form-group
              label="Two Factor Code"
              label-for="two-factor-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Two Factor Code"
                rules="required"
              >
                <b-form-input
                  id="two-factor-code"
                  v-model="twoFactorCode"
                  :state="errors.length > 0 ? false : null"
                  name="two-factor-code"
                  placeholder="............."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <SpinnerBtn v-if="isLoading" />
            <!-- submit button -->
            <b-button
              v-else
              variant="primary"
              block
              type="submit"
            >
              Proceed
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link
            v-if="isHiringManager"
            :to="{ name: 'auth-login' }"
            @click="$emit('other-profile-update', { status: 'login' })"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
          <b-link
            v-else
            :to="{ name: 'auth-login' }"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    SpinnerBtn,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isHiringManager: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
      twoFactorCode: '',
      isLoading: false,

      required,
    }
  },
  computed: {},

  created() {},

  methods: {
    checkEvent() {
      const currentEvent = localStorage.getItem('current_event')
        ? localStorage.getItem('current_event')
        : ''

      if (currentEvent) {
        if (currentEvent.length > 0) {
          return true
        }
        return false
      }
      return false
    },
    confirmTwoFactor() {
      this.isLoading = true
      this.$http
        .post('/api/auth/two-factor', {
          two_factor_code: this.twoFactorCode,
          email_address: localStorage.getItem('email'),
        })
        .then(response => {
          this.isLoading = false
          if (response.data.token) {
            this.$store
              .dispatch('auth/approveLogin', {
                response,
                isHiringManager: this.isHiringManager,
              })
              .then(() => {
                // localStorage.removeItem('email')
              })
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Invalid Two Factor Code',
                  icon: 'AlertCircleIcon',
                  text: 'Please input the valid two factor code',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Invalid Two Factor Code',
                icon: 'AlertCircleIcon',
                text: 'Please input the valid two factor code',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
